import {useContext, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import AureliaContext from "../../utilities/aurelia-context";
import {Button, FlexboxGrid, InputPicker, Modal, Slider, Stack, Table, Tooltip, Whisper} from "rsuite";
import {Check, Plus} from "@rsuite/icons";
import StatsLabel from "../../statistics/time-aggregation/stats-label";
import {useStyles} from "../origin/constants";
import {CurrencyInput} from "../../form/rsuite-form";
import SioIcon from "../../icon/rsuite-icon-font/SioIcon";
import confirm from "../../dialog/confirm";
import {useDispatch} from "../../store/store";
import {AccountSelect, TAX} from "../../accounting/entry/form-elements";
import Panel from "../../view-block/panel/react-panel";
import {EditCell} from "./edit-cell";
import {
    useAddMarginBookingsMutation,
    useChangeMarginBookingMutation,
    useDeleteMarginBookingMutation
} from "../store/costs-api";
import {
    addPercentSplit,
    changePercentSplit,
    removePercentSplit,
    resetPercentSplits,
    selectCanSave,
    selectPercentSplit,
    selectPercentSplitIds,
    selectPercentSplitSave,
    setPercentSplitAccount
} from "../store/report-slice";
import {ActionButton} from "../../action/react/action-button";

const actionConfig = {
    type: "workflow",
    formId: "costs/add-margin-booking",
    workflowId: "costs/add-margin-booking",
    label: <Plus width=".667em" height=".667em"/>,
    confirmTitle: "costs.add-margin-booking",
    buttonClass: "btn btn-success",
}

const bookConfig = {
    type: "workflow",
    workflowId: "costs/book-margin-report",
    formId: "costs/book-margin-report",
    label: "costs.book-margin-report-short",
    confirmTitle: "costs.book-margin-report",
    buttonClass: "btn btn-danger",
}

/**
 * @param {ObjectRef} reference
 * @param {MarginBooking[]} marginBookings
 * @param {Money} unassignedMargin
 * @param {object[]} labels
 * @param {Money} realMargin
 * @param {string} organizationId
 * @param {function} refetch
 */
export function MarginBookings(
    {
        identifier,
        marginBookings,
        unassignedMargin,
        labels,
        realMargin,
        organizationId,
        refetch,
        locked,
        readonly,
        canBook
    }
) {
    const {i18n, currencyValueConverter, percentageValueConverter} = useContext(AureliaContext)
    const data = Object.entries(marginBookings).map(([identifier, {amount, taxRate, fixedFraction, description}]) => (
        {
            identifier,
            fixedFraction: fixedFraction && percentageValueConverter.toView(fixedFraction, "0%"),
            description,
            account: <StatsLabel label={labels[identifier]}/>,
            amount: (readonly ? (
                    amount?.amount && currencyValueConverter.toView(amount)
                ) : (
                    <EditCell
                        value={amount}
                        renderer={amount => currencyValueConverter.toView(amount)}
                        editor={CurrencyInput}
                        mutation={useChangeMarginBookingMutation}
                        transformSave={amount => ({identifier, amount})}
                        readonly={locked}
                        stack={{justifyContent: "flex-end"}}
                    />
                )
            ),
            taxRate: (
                <EditCell
                    value={taxRate ?? "0"}
                    renderer={taxRate => percentageValueConverter.toView(taxRate, '0%')}
                    mutation={useChangeMarginBookingMutation}
                    transformSave={taxRate => ({identifier, taxRate})}
                    editor={InputPicker}
                    data={TAX}
                    cleanable={false}
                    readonly={readonly || locked}
                    stack={{justifyContent: "flex-end"}}
                />
            ),
            canDelete: !locked && !readonly,
        }
    )).concat(locked ? [] : [
        {
            identifier: "__footer",
            account: unassignedMargin?.amount ? (
                <strong>{i18n.tr("costs.still-unassigned")}</strong>
            ) : canBook && (
                <ActionButton
                    config={bookConfig}
                    context={{data: {formData: {identifier}}}}
                    onSubmitted={() => refetch()}
                />
            ),
            amount: unassignedMargin?.amount ? (
                <strong>{currencyValueConverter.toView(unassignedMargin)}</strong>
            ) : (
                <Check/>
            ),
            canDelete: false,
        }
    ])

    const hasFixedFraction = data.some(({fixedFraction}) => !!fixedFraction)

    return (
        <Panel
            heading="costs.margin-bookings"
            reference={{data: {formData: {identifier}}}}
            onSubmitted={() => refetch()}
            actionConfig={locked || readonly ? null : actionConfig}
            additionalHeader={
                (Object.keys(marginBookings).length && realMargin?.amount) || readonly || locked ? null : (
                    <PercentageSplits identifier={identifier} realMargin={realMargin}
                                      organizationId={organizationId}/>
                )
            }
        >
            <Table autoHeight data={data} rowKey="identifier" wordWrap="break-word" headerHeight={60}>
                {hasFixedFraction && (
                    <Table.Column width={50}>
                        <Table.HeaderCell>%</Table.HeaderCell>
                        <Table.Cell dataKey="fixedFraction"/>
                    </Table.Column>
                )}

                <Table.Column flexGrow={1}>
                    <Table.HeaderCell>
                        <Stack spacing={2} direction="row" alignItems="flex-end">
                            <span>{i18n.tr("accounting.field.account")}</span>
                            <strong><StatsLabel label={labels.account}/></strong>
                        </Stack>
                    </Table.HeaderCell>

                    <Table.Cell dataKey="account"/>
                </Table.Column>

                <Table.Column flexGrow={1} align="right">
                    <Table.HeaderCell>
                        {i18n.tr("accounting.field.amount")}<br/>
                        <strong>{currencyValueConverter.toView(realMargin) + "\u00A0an"}</strong>
                    </Table.HeaderCell>

                    <Table.Cell dataKey="amount"/>
                </Table.Column>

                <Table.Column flexGrow={1} align="right">
                    <Table.HeaderCell>
                        {i18n.tr("accounting.label.tax")}<br/>
                        <strong>&nbsp;</strong>
                    </Table.HeaderCell>

                    <Table.Cell dataKey="taxRate"/>
                </Table.Column>

                <Table.Column width={30}>
                    <Table.HeaderCell>{""}</Table.HeaderCell>
                    <Table.Cell>
                        {({identifier, canDelete}) => canDelete && (
                            <DeleteButton identifier={identifier}/>
                        )}
                    </Table.Cell>
                </Table.Column>
            </Table>
        </Panel>
    )
}

function DeleteButton({identifier}) {
    const {i18n} = useContext(AureliaContext);
    const {danger, btn} = useStyles();
    const [doDelete, {isLoading}] = useDeleteMarginBookingMutation();

    const onClick = () => confirm(i18n.tr("costs.delete-margin-booking"), i18n.tr("sio.are_you_sure"))
        .then(() => doDelete(identifier));

    return (
        <Button loading={isLoading}
                onClick={() => onClick()}
                size="xs"
                className={danger + " " + btn}
                appearance="link">

            <SioIcon icon="fa fa-trash"/>
        </Button>
    );
}

/**
 * @param {string} identifier
 * @param {Money} realMargin
 * @param {string} organizationId
 */
function PercentageSplits({identifier, realMargin, organizationId}) {
    const dispatch = useDispatch()
    const [doSave, {isLoading, isSuccess, isError}] = useAddMarginBookingsMutation()
    const ids = useSelector(selectPercentSplitIds)
    const canSave = useSelector(selectCanSave)
    const splitsSave = useSelector(selectPercentSplitSave)
    const [open, setOpen] = useState(false)
    const showDelete = 2 < ids.length

    function save() {
        doSave({identifier, amounts: splitsSave})
    }

    useEffect(() => {
        if (isSuccess) {
            setOpen(false)
        }
    }, [isLoading])

    useEffect(() => {
        if (!open && !isError) {
            dispatch(resetPercentSplits(realMargin))
        }
    }, [realMargin.amount, realMargin.currency, open])

    return (
        <>
            <Whisper speaker={<Tooltip>Prozentual aufteilen</Tooltip>}>
                <Button size="sm" loading={open} onClick={() => setOpen(true)}>%</Button>
            </Whisper>
            <Modal open={open} onClose={() => setOpen(false)} size="lg">
                <Modal.Header>
                    <Modal.Title>Prozentual aufteilen</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <FlexboxGrid justify="space-between">
                        {ids.map(id => (
                            <PercentageSplit key={id} id={id} currency={realMargin.currency}
                                             organizationId={organizationId} showDelete={showDelete}/>
                        ))}
                        <FlexboxGrid.Item colspan={24}>
                            <Button appearance="link" size="xs"
                                    onClick={() => dispatch(addPercentSplit())}><Plus/></Button>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </Modal.Body>

                <Modal.Footer>
                    <Button onClick={() => save()} disabled={!canSave} loading={isLoading} appearance="primary">
                        OK
                    </Button>
                    <Button onClick={() => setOpen(false)} appearance="subtle">
                        Abbrechen
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

function PercentageSplit({id, currency, showDelete, organizationId}) {
    const {currencyValueConverter, percentageValueConverter} = useContext(AureliaContext)
    const dispatch = useDispatch()
    const {account, percentage, amount} = useSelector(state => selectPercentSplit(state, id))

    return (
        <>
            <FlexboxGrid.Item colspan={6}>
                <AccountSelect value={account} organization={organizationId} placeholder="Konto"
                               onChange={account => dispatch(setPercentSplitAccount(id, account))}/>
            </FlexboxGrid.Item>

            <FlexboxGrid.Item colspan={11} style={{paddingTop: "8px"}}>
                <Slider
                    tooltip={false}
                    value={percentage} onChange={value => dispatch(changePercentSplit(id, value))}
                    min={.0} max={1.0} step={.01}
                />
            </FlexboxGrid.Item>

            <FlexboxGrid.Item colspan={2} style={{textAlign: "right"}}>
                {percentageValueConverter.toView(percentage, "0%")}
            </FlexboxGrid.Item>

            <FlexboxGrid.Item colspan={2} style={{textAlign: "right"}}>
                {currencyValueConverter.toView({amount, currency})}
            </FlexboxGrid.Item>

            <FlexboxGrid.Item colspan={2}>
                {showDelete && (
                    <Button appearance="link" size="xs" onClick={() => dispatch(removePercentSplit(id))}>
                        <SioIcon icon="fa fa-trash"/>
                    </Button>
                )}
            </FlexboxGrid.Item>
        </>
    )
}
