import {bindable, customElement, inject} from "aurelia-framework";
import {EventAggregator} from "aurelia-event-aggregator";
import * as _ from "lodash";
import {Client} from "../api/client";
import {SubmitHandler} from "../form/submit/submit-handler";
import {FlashService} from "../flash/flash-service";
import {BindingSignaler} from "aurelia-templating-resources";

@customElement('questions-editor')
@inject(Client, SubmitHandler, EventAggregator, FlashService, BindingSignaler)
export class QuestionsEditor {

    @bindable template;

    client;
    submit;
    ea;
    flash;
    signaler;

    questions = [];
    groups = [];
    selected = [];

    constructor(client, submit, ea, flash, signaler) {
        this.client = client;
        this.submit = submit;
        this.ea = ea;
        this.flash = flash;
        this.signaler = signaler;
    }

    attached() {
        let convertList = (list, target) => {
            _.forEach(list.items, obj => {
                target[obj.id] = obj;
            });
        };

        this.selected = [];
        this.groups = [];
        this.questions = [];

        this.client.get('crm-qa-survey/question_group').then(groupList => {
            _.forEach(groupList.items, group => {
                this.groups.push(group);
            });

            this.client.get('crm-qa-survey/question').then(questionList => {
                _.forEach(questionList.items, question => {
                    this.questions.push(question);
                });

                _.forEach(this.groups, group => {
                    group.questions = [];
                    group.selected = [];

                    _.forEach(this.questions, question => {
                        if (question.group.id === group.id) {
                            group.questions.push(question);
                        }
                    });
                });

                this.template.questions = this.template.questions
                    .sort((a, b) => a.position - b.position)
                    .map(item => item.question);

                _.forEach(this.template.questions, question => {
                    this.addQuestion(question.id);
                });

                this.signaler.signal('survey-questions');
            });
        });
    }

    detached() {
        this.selected = [];
        this.groups = [];
        this.questions = [];
    }

    search(list, id) {
        let result = undefined;

        if (list.length > 0) {
            _.forEach(list, item => {
                if (item.id === id) {
                    result = item;
                    return false;
                }
            });
        }

        return result;
    }

    addQuestion(questionId) {
        let question = this.search(this.questions, questionId);
        let group = this.search(this.groups, question.group.id);

        if (this.search(group.selected, questionId)) {
            return;
        }

        if (!this.search(this.selected, group.id)) {
            this.selected.push(group);
            group.selected = [];
        }

        group.selected.push(question);

        this.signaler.signal('survey-questions');

        this.updateTemplate();
    }

    removeQuestion(list, index, parentIndex) {
        list.splice(index, 1);

        if (0 === list.length) {
            this.selected.splice(parentIndex, 1);
        }

        this.signaler.signal('survey-questions');
    }

    updateTemplate() {
        this.template.questions = [];

        let i = 0;

        _.forEach(this.selected, group => {
            _.forEach(group.selected, question => {
                question.group = group;
                this.template.questions.push({position: i++, question: question});
            });
        });

        this.signaler.signal('survey-template');
    }

    save() {
        let questions = [];
        let i = 0;

        _.forEach(this.selected, group => {
            _.forEach(group.selected, question => {
                questions.push({position: i++, question: {modelId: question.modelId, id: question.id}});
            });
        });

        this.submit.apiSubmit({
            config: {moduleId: 'crm-qa-survey', id: 'survey_template_questions'},
            identifier: this.template.id,
            object: {questions: questions}
        }).then(
            () => {
                this.flash.success('form.success');
            },
            error => {
                let data = error.data;

                if (data) {
                    if (data.localizedMessage) {
                        this.flash.error(data.localizedMessage);
                    }
                }

                if (400 !== error.status) {
                    this.flash.error('form.communication_error_message');
                } else {
                    this.flash.error('form.validation_error_message');
                }
            }
        );
    }

    move(list, oldIndex, newIndex) {
        let item = list[oldIndex];
        list.splice(oldIndex, 1);
        list.splice(newIndex, 0, item);
        this.updateTemplate();

        this.signaler.signal('survey-questions');
    }
}

