let content = new Map();

export default class EditorContent {

    static id = 0;
    static reference;
    static public;
    static gutenberg;

    static init() {
        import(/* webpackMode: "lazy", webpackChunkName: "editor" */ "@wordpress/data")
            .then(({select}) => EditorContent.gutenberg = select('core/editor'));
    }

    static get content() {
        console.debug('get content', EditorContent.id);

        if (!content.has(EditorContent.id)) {
            console.debug('cleared');
            content.set(EditorContent.id, '');
            return '';
        }

        const editorContent = EditorContent.gutenberg?.getEditedPostContent();

        if (editorContent) {
            console.debug('using current editor content', editorContent);
            return editorContent;
        }

        return content.get(EditorContent.id) || '';
    }

    static set content(data) {
        console.debug('set content', {data, id: EditorContent.id});
        content.set(EditorContent.id, "" + (data || ""));
    }

    static clear(id) {
        content.delete(id ? parseInt(id) : EditorContent.id);
        localStorage.removeItem('WP_DATA_USER_' + id);
        sessionStorage.removeItem('wp-autosave-block-editor-post-' + id);
    }

    static save(id, data = "") {
        console.debug('save editor content', {id, data});
        content.set(parseInt(id), data);
    }

    static load(id, useEditor = false) {
        id = parseInt(id);

        console.debug('load editor content', {id});

        if (useEditor) {
            const editorContent = EditorContent.gutenberg?.getEditedPostContent();

            console.debug('using current editor content', editorContent);
            return editorContent;
        }

        console.debug('using stored content', content.get(id), Array.from(content.entries()));

        return content.get(id) || '';
    }
}
