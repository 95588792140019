import React, {PureComponent} from 'react';
import {ModelLabelService} from "../../reference/model-label-service";
import {Container} from "aurelia-framework";

export default class ReactReferenceDisplay extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            links: []
        };
    }

    componentDidMount() {
        this.reload();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.reference !== this.props.reference) {
            this.reload();
        }
    }

    reload()
    {
        let modelLabelService = Container.instance.get(ModelLabelService);

        if (!this.props.reference) {
            this.setState({links: []});
        }

        let references = this.props.reference;

        if (!Array.isArray(references)) {
            references = [references];
        }

        references = references.map((reference) => {
            return modelLabelService.getReferenceLinks(reference, false);
        });

        Promise.all(references).then((links) => {

            let newLinks = [];

            links.forEach(link => {

                if (link) {
                    newLinks.push(link);
                }
            });

            this.setState({links: newLinks});
        });
    }

    render() {

        return <>
            {this.state.links.map((link, index) => (
                <span key={index}>{index != 0 ? ', ' : ''}{link.label}</span>
            ))}
        </>;
    }

}
