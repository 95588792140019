import {inject} from "aurelia-framework";
import {DialogController, DialogService} from "aurelia-dialog";
import EditorContent from "./utils/editor-content";
import {Client} from "../api/client";
import {Confirm} from "../dialog/confirm";
import {FlashService} from "../flash/flash-service";
import {EventAggregator} from "aurelia-event-aggregator";

import "./editor-modal.less";
import {UnsavedChanges} from "../form/unsaved-changes-prevent";
import {WebsiteContextClient} from "./utils/website-context-client";
import queryString from "../utilities/query-string";

@inject(DialogController, DialogService, Client, FlashService, EventAggregator, WebsiteContextClient)
export default class EditorModal {

    label;
    context;
    blocks;
    data;
    editorId;
    divId;

    previewWindow;

    saveAction = {
        buttonClass: "btn btn-danger btn-sm action-ok",
        label: "form.save"
    }

    previewAction = {
        buttonClass: "btn btn-info",
        label: "Vorschau"
    }

    constructor(dialog, dialogService, client, flash, ea, websiteContextClient) {
        this.dialog = dialog;
        this.dialogService = dialogService;
        this.client = client;
        this.flash = flash;
        this.ea = ea;
        this.websiteContextClient = websiteContextClient;
    }

    activate = ({label, context, blocks, content, editor, formId, modelId, id, property, formConfig, reference, data, public: p}) => {
        this.label = label || 'Editor';
        this.context = context;
        this.blocks = blocks;
        this.content = content;
        this.editor = editor;
        this.formId = formId;
        this.id = id;
        this.modelId = modelId;
        this.property = property;
        this.formConfig = formConfig;
        this.editorId = ++EditorContent.id;
        this.data = data;
        this.divId = 'gutenberg__editor_' + this.editorId;
        this.showPreview = /^(cms\/(content-page|email-template)|email-campaign\/campaign)$/.test(reference?.modelId);

        EditorContent.reference = reference;
    };

    attached() {
        const query = queryString({
            reference: {
                id: EditorContent.reference.id,
                modelId: EditorContent.reference.modelId
            }
        });

        this.client.get('editor/dynamic-block-configuration?' + query).then(dynamicBlockConfig => {

            import(
                /* webpackMode: "lazy" */
                /* webpackChunkName: "editor" */
                "./utils/editor-loader"
                ).then(async ({initializeEditor, shutdownEditor}) => {
                this.shutdownEditor = shutdownEditor;

                return initializeEditor(
                    this.editorId,
                    this.blocks,
                    this.content,
                    dynamicBlockConfig,
                    await this.websiteContextClient.getWebsite(this.data?.website)
                );
            });

            window.addEventListener('keypress', this._keypress);
        });
    };

    detached() {
        this.shutdownEditor(this.editorId);
        window.removeEventListener('keypress', this._keypress);

        // @fixme force reload to avoid endless loop in Gutenberg
        UnsavedChanges.ignore = true;
        setTimeout(() => window.location = window.location, 200);
    }

    cancel() {
        this.dialog.cancel();
    }

    save() {
        const content = EditorContent.load(this.editorId, true);

        if (!this.formId || !this.id || !this.property) {
            this.editor.data = content;
            return this.dialog.ok();
        }

        return this.client.put(this.formId + '/' + this.id, {[this.property]: content}).then(
            response => {
                this.ea.publish('sio_form_post_submit', {config: this.formConfig, response});
                this.flash.success('form.success');
                this.dialog.ok();

                return response;
            }, error => {
                console.error(error);
                this.flash.error(400 === error.status ? 'Ungültige Formulardaten. Bitte kontrollieren Sie, ob alle Blöcke korrekt ausgefüllt sind.' : 'form.error');
            });
    }

    preview() {
        console.debug({preview: EditorContent.load(this.id, true)});

        let endpoint;

        switch (EditorContent.reference.modelId) {

            case 'cms/content-page':
                endpoint = 'cms/content-page-editor-preview';
                break;

            case 'cms/email-template':
                endpoint = 'cms/email-template-preview-create';
                break;

            case 'email-campaign/campaign':
                endpoint = 'email-campaign/email-campaign-preview';
                break;

            default:
                return null;
        }

        return this.client
            .patch(
                endpoint + '/trigger/' + EditorContent.reference.id,
                {document: EditorContent.load(this.id, true)}
            )
            .then(({data}) => {
                if (this.previewWindow == null || this.previewWindow.closed) {
                    this.previewWindow = window.open(data[0].url);
                } else {
                    this.previewWindow.location.href = data[0].url;
                    this.previewWindow.focus();
                }
            });
    }

    canDeactivate = ({wasCancelled}) => {

        if (wasCancelled && this.content !== EditorContent.content) {
            return this.dialogService.open(
                {
                    viewModel: Confirm,
                    model: {
                        message: "Wollen Sie wirklich ungespeicherte Änderungen verwerfen?",
                        title: this.label,
                        okMessage: 'Verwerfen',
                        cancelMessage: 'sio.cancel'
                    }
                }
            ).whenClosed(({wasCancelled}) => !wasCancelled);
        }

        return true;
    }

    _keypress = ({keyCode}) => 27 === keyCode ? this.dialog.cancel() : true;
}
